import axios from "axios";
import { ApiService } from "../../../utilities/interceptor/Interceptor";
import { Environment } from "../../../Shared/constants/AppConstants";

export const getUserData = (page: number) => {
  return ApiService.get(`/user/user-list?page=${page}`);
};
export const deleteUser = (id: number) => {
  return ApiService.delete(`/user/delete-user?user_id=${id}`);
};
export const allowStatus = (data: any) => {
  return ApiService.patch("/user/allow-user", data);
};
export const EditUser = (data: any) => {
  return ApiService.patch(`/user/update-user`, data);
};
export const getTickets = (page: number) => {
  return ApiService.get(`/ticket/ticket-list?page=${page}`);
};
export const getTicketsAll = (page: number) => {
  return ApiService.get(`/ticket/ticket-list?get_all=True&page=${page}`);
};
export const postOccupation = (data: any) => {
  return ApiService.post(`/user/occupations`, data);
};
export const deleteEntity = () => {
  return ApiService.delete(`/user/delete-user`);
};
export const getPage = (name: string) => {
  return axios.get(`${Environment.base_url}/user/pages-list?page_name=${name}`);
};
export const updateContent = (id: number, data: any) => {
  return ApiService.put(`/user/pages/${id}`, data);
};
export const deleteticket = (id: number) => {
  return ApiService.delete(`/ticket/delete-ticket?ticket_id=${id}`);
};
