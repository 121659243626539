import { Environment } from "../../Shared/constants/AppConstants";
import { ApiService } from "../../utilities/interceptor/Interceptor";

import axios from "axios";
export const postLogin = (data: any) => {
  return axios.post(Environment.base_url + "/user/login", data);
};
export const postSignUp = (data: any) => {
  return axios.post(Environment.base_url + "/user/signup", data);
};
export const postVerifyCode = (data: any) => {
  return axios.post(Environment.base_url + "/user/verify-email", data);
};

export const getOccupation = () => {
  return axios.get(Environment.base_url + "/user/get-occupations");
};

export const sendEmail = (email: string) => {
  const payload = {
    email: email,
  };
  return ApiService.post("/user/forgot-password", payload);
};

export const setPassword = (data: any) => {
  const payload = {
    old_password: data.old_password,
    new_password: data.new_password,
  };
  return ApiService.post("/user/update-password", payload);
};
export const getUser = () => {
  return ApiService.get("user/user-information");
};
