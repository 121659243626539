import React, { useState } from "react";
import { useAppSelector } from "../../hooks/AppHooks";
import { postVerifyCode } from "../service/SignInServices";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AnimatedLoader from "../../Shared/components/ui_componenets/animatedloader/AnimatedLoader";

const EmailVerify = () => {
  const navigate = useNavigate();
  const email = useAppSelector((state: any) => state.signUpEmail.signUp_email);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: email,
    verification_code: "",
  });

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.stopPropagation();
    } else {
      setLoading(true);
      postVerifyCode(formData).then((res) => {
        if (res.data.statusCode === 200) {
          toast.success(res.data.statusMessage);
          setLoading(false);
          toast.success("Admin Approval yet required ");
          navigate("/");
        } else {
          toast.error(res.data.statusMessage);
          setLoading(false);
        }
      });
      // Handle form submission
    }

    setValidated(true);
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center page-login">
      <form
        className={`col-md-4 col-lg-4 col-sm-12 g-3 loginform ${
          validated ? "was-validated" : ""
        }`}
        noValidate
        onSubmit={handleSubmit}
      >
        <h1 className="mb-3 d-flex justify-content-center">
          <span className="text_primary">PLA</span>IT
        </h1>
        {/* <div className="form-floating  mb-3">
          <input
            type="email"
            className="form-control"
            id="floatingInput"
            name="email"
            value={email}
            placeholder="name@example.com"
            required
          />
          <label htmlFor="floatingInput">Email address</label>
          <div className="valid-feedback">Looks good!</div>
        </div> */}
        <div className="mb-3 w-100">
          <label htmlFor="username" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="username"
            name="email"
            value={email}
            placeholder="name@example.com"
            required
          />
        </div>

        {/* <div className="form-floating mb-3">
          <input
            type="password"
            name="verification_code"
            className="form-control"
            id="floatingVerify"
            value={formData.verification_code}
            onChange={handleInputChange}
            placeholder="Email Verify Code"
            required
          />
          <label htmlFor="floatingVerify">Email Verify Code</label>
          <div className="valid-feedback">Looks good!</div>
        </div> */}
        <div className="mb-3 w-100">
          <span className="  d-flex justify-content-start text_sm">
            Enter your verify code from your &nbsp;
            <span className="text_primary cursor-pointer">Email*</span>
          </span>
          <input
            type="text"
            name="verification_code"
            className="form-control"
            id="verifycode"
            value={formData.verification_code}
            onChange={handleInputChange}
            placeholder="Email Verify Code"
            required
          />
        </div>

        <div className="col-12">
          <button className="btn btn_primary" type="submit">
            Verify
          </button>
        </div>
      </form>
      {/* {loading && <AnimatedLoader />} */}
    </div>
  );
};

export default EmailVerify;
