import React from "react";

const Manual = () => {
  return (
    <>
      <div className="page-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 wow fadeInUp">
              <h1 className="text-center mb-3">Manual</h1>
              <div className="text-lg">
                <p>
                In this section you will get more information and instruction how to use RNAdecode.AI.
                </p>
                <p>
                Please be aware that this project is at the moment a working Demo of an interactive platform, which can be optimized with your suggestions and comments.
                </p>
                <p>
                We are glad to receive your feedback via e-mail.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Manual;