import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthenticationTabState, Tab } from "../model/LoginModel";

const signUpEmailInitialState = {
  signUp_email: "",
};
export const signUpEmailReducer = createSlice({
  name: "signUpEmailReducer",
  initialState: signUpEmailInitialState,
  reducers: {
    setSignUpEmail: (state, action: PayloadAction<string>) => {
      state.signUp_email = action.payload;
    },
  },
});
export const { setSignUpEmail } = signUpEmailReducer.actions;

const authanticationTabInitialState: AuthenticationTabState = {
  tabs: [],
};
export const authanticationTabReducer = createSlice({
  name: "authanticationTabReducer",
  initialState: authanticationTabInitialState,
  reducers: {
    setTabs: (state, action: PayloadAction<Tab[]>) => {
      state.tabs = action.payload;
    },
  },
});
export const { setTabs } = authanticationTabReducer.actions;
export default authanticationTabReducer.reducer;
