export const user = [
  {
    id: 0,
    name: "TCGA ",
    title: "/marburg",
  },
  {
    id: 1,
    name: "Ticket",
    title: "/ticket",
  },
];
export const supperUser = [
  {
    id: 0,
    name: "Admin",
    title: "/admin",
  },
  {
    id: 1,
    name: "TCGA",
    title: "/marburg",
  },
  {
    id: 2,
    name: "Ticket",
    title: "/ticket",
  },
];
