import React, { useState } from "react";
import "./Home.css";
import User from "./users/User";
import AdminTicket from "./Ticket/AdminTicket";
import DataEdit from "./dataEdit/DataEdit";

const Home = () => {
  const [subHeader, setSubHeader] = useState("adminuser");

  return (
    <div className="min-vh-50 mt-3 mb-3">
      <nav className="container py-2  AdminSubheader ">
        <div className="nabTablist">
          <ul className="bg-body-tertiary">
            <li className="">
              <a
                className={`navTab ${
                  subHeader === "adminuser" ? "active" : ""
                }`}
                onClick={() => setSubHeader("adminuser")}
                style={{ cursor: "pointer" }}
              >
                User
              </a>
            </li>
            <li className="ml-2">
              <a
                className={`navTab ${
                  subHeader === "adminticket" ? "active" : ""
                }`}
                onClick={() => setSubHeader("adminticket")}
                style={{ cursor: "pointer" }}
              >
                Ticket
              </a>
            </li>
            
          </ul>
        </div>
      </nav>

      {subHeader === "adminuser" ? (
        <User />
      ) : subHeader === "adminticket" ? (
        <AdminTicket />
      ) :  (
        ""
      )}
    </div>
  );
};

export default Home;
