import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";
import {
  authanticationTabReducer,
  signUpEmailReducer,
} from "./login/reducer/reducer";

const reducer = combineReducers({
  signUpEmail: signUpEmailReducer.reducer,
  authTabs: authanticationTabReducer.reducer,
});
export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
