import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  deleteticket,
  getTickets,
  getTicketsAll,
} from "../service/HomeServices";
import "../Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../../Shared/components/pagination/Pagination";
import OverView from "../overview/OverView";
import { useAppDispatch } from "../../../hooks/AppHooks";
import { setTabs } from "../../../login/reducer/reducer";
import swal from "sweetalert";
import { Environment } from "../../../Shared/constants/AppConstants";

const AdminTicket = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  useEffect(() => {
    getAllTickets();
  }, []);
  const getAllTickets = () => {
    getTicketsAll(currentPage)
      .then((res) => {
        if (res.statusCode === 200) {
          setData(res);
          setTotalPages(res.total_pages);
        } else if (res.statusCode === 401) {
          sessionStorage.setItem("accessToken", "");
          sessionStorage.setItem("is_superuser", "");
          dispatch(setTabs([]));
          navigate("/");
        } else {
          toast.error(res.statusMessage);
        }
      })
      .catch(() => {
        sessionStorage.setItem("accessToken", "");
        sessionStorage.setItem("is_superuser", "");
        dispatch(setTabs([]));
        navigate("/");
      });
  };
  const extractFileName = (url: string) => {
    if (!url) return;
    const parts = url?.split("/");
    return parts[parts?.length - 1].length > 7
      ? parts[parts?.length - 1].substring(0, 6) + "..."
      : parts[parts?.length - 1];
  };
  const handleFileDownload = (url: string) => {
    window.open(Environment.base_url+url);
  };
  const downloadImage = (imageUrl: string) => {
    const fullUrl = Environment.base_url + imageUrl
    fetch(fullUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'downloaded_image.png'; // Set the desired file name
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch(error => console.error('Error downloading the image:', error));
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    getAllTickets();
  }, [currentPage]);

  const handleDelete = (id: number) => {
    swal({
      title: "Are you sure?",
      text: "This will delete all your data from our database?",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
          className: "bg-success text-white",
          closeModal: true, // Specify whether clicking cancel should close the dialog
        },
        confirm: {
          text: "Delete",
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: false,
        },
      },
    }).then(async (willDelete) => {
      if (willDelete) {
        swal("Changed!", "Your item has been Change.", "success");

        deleteticket(id)
          .then((res) => {
            if (res.statusCode === 200) {
              toast.success(res.statusMessage);
              getAllTickets();
            } else if (res.statusCode === 401) {
              sessionStorage.setItem("accessToken", "");
              sessionStorage.setItem("is_superuser", "");
              dispatch(setTabs([]));
              navigate("/");
            } else {
              toast.error(res.statusMessage);
            }
          })
          .catch(() => {
            sessionStorage.setItem("accessToken", "");
            sessionStorage.setItem("is_superuser", "");
            dispatch(setTabs([]));
            navigate("/");
          });
      } else {
        swal("Cancelled", "Your item is safe :)", "error");
      }
    });
  };

  return (
    <>
      <div className={`container py-2 user-select-none`}>
        <div className="infoBox">
          <div className="infoBoxTab px-2">
            <h6 className="infoBoxhead">Total Tickets</h6>
            <h1 className="infoBoxInformation">{data?.total_tickets}</h1>
          </div>
          <div className="infoBoxTab px-2">
            <h6 className="infoBoxhead">Tickets in Queue</h6>
            <h1 className="infoBoxInformation">{data?.queue_count}</h1>
          </div>
          <div className="infoBoxTab px-2">
            <h6 className="infoBoxhead">Tickets in Progress</h6>
            <h1 className="infoBoxInformation">{data?.in_progress_count}</h1>
          </div>
          <div className="infoBoxTab px-2">
            <h6 className="infoBoxhead">Tickets that Failed</h6>
            <h1 className="infoBoxInformation">{data?.failed_count}</h1>
          </div>
        </div>

        <div className=" py-3 ">
          <table className="table table-striped border">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Ticket</th>
                <th scope="col">User</th>
                <th scope="col">Uploaded file</th>
                <th scope="col">Status</th>
                <th scope="col">Created</th>
                {/* <th scope="col">Last Updated</th> */}
                <th scope="col">Description</th>
                <th scope="col">Results</th>
                <th scope="col">OverView</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((item: any, i: number) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.user_email}</td>
                  <td className="">
                    <div className="d-flex justify-content-between">
                      <div title={item.file}>{extractFileName(item.file)}</div>
                      <div
                        onClick={() => handleFileDownload(item.file)}
                        style={{ cursor: "pointer" }}
                      >
                        <FontAwesomeIcon icon={faDownload} size="sm" />
                      </div>
                    </div>
                  </td>
                  <td>{item.status}</td>
                  <td>{item.created_at}</td>
                  {/* <td>{item.updated_at}</td> */}
                  <td title={item.description}>
                    {item.description.length > 20
                      ? item.description.substring(0, 20)
                      : item.description}
                  </td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div title={item.file}>
                        {extractFileName(item.result)}
                      </div>
                      <div
                        onClick={() => downloadImage(item.result)}
                        style={{ cursor: "pointer" }}
                      >
                        {item.result ? (
                          <FontAwesomeIcon icon={faDownload} size="sm" />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <OverView data={item} />
                  </td>
                  <td>
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      className="text-danger py-1 px-2 rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDelete(item.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default AdminTicket;
