import React, { useState } from "react";
import "./SignIn.css";
import { postLogin } from "../service/SignInServices";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../hooks/AppHooks";
import { setTabs } from "../reducer/reducer";
import { supperUser, user } from "../contants/Constants";
import AnimatedLoader from "../../Shared/components/ui_componenets/animatedloader/AnimatedLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      postLogin(formData)
        .then((res) => {
          if (res.data.statusCode === 200) {
            const { is_superuser, access } = res.data.data;
            sessionStorage.setItem("accessToken", access);
            sessionStorage.setItem("is_superuser", is_superuser);
            const tabs = is_superuser ? supperUser : user;
            dispatch(setTabs(tabs));
            setLoading(false);
            navigate("/marburg");
          } else {
            toast.error(res.data.statusMessage);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Login error:", error);
          toast.error("An error occurred during login.");
        });
    }

    setValidated(true);
  };

  const handleChangPage = () => {
    navigate("/signup");
  };
  const handleForgetPassword = () => {
    navigate("/forgetemail");
  };

  return (
    <div className="mt-5 mb-5">
      <div className="d-flex flex-column justify-content-center align-items-center page-login ">
        <form
          className={`col-md-4 col-lg-4 col-sm-12 loginform ${
            validated ? "was-validated" : ""
          }`}
          noValidate
          onSubmit={handleSubmit}
        >
          <h1 className="mb-3 d-flex justify-content-center">
            <span className="text_primary">LOG</span>IN
          </h1>
          <div className="mb-3 w-100">
            <label htmlFor="textinfo" className="form-label">
              User Name
            </label>
            <input
              type="text"
              className="form-control"
              id="textinfo"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder=" name or name@example.com"
              required
            />
          </div>
          <div className="mb-3 w-100">
            <label htmlFor="passwordtab" className="form-label">
              Password
            </label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                className="form-control"
                id="passwordtab"
                style={{ borderRight: 0 }}
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Password"
                required
              />
              <button
                className="btn text_primary"
                type="button"
                style={{
                  backgroundColor: "#E8F0FE",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderLeft: 0,
                  borderTop: "1px solid #ced4da",
                  borderBottom: "1px solid #ced4da",
                  borderRight: "1px solid #ced4da",
                  outline: "none",
                  boxShadow: "none",
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </button>
            </div>
            <span className=" d-flex justify-content-start text_xs">
              create new account &nbsp;
              <span
                className="text_primary cursor-pointer"
                onClick={handleChangPage}
                style={{ cursor: "pointer" }}
              >
                SignUp*
              </span>
            </span>
          </div>
          <div className="d-flex justify-content-end w-100">
            <button className="btn btn_primary" type="submit">
              LogIn
            </button>
          </div>
          <span className=" d-flex justify-content-center text_xs">
            Forget your &nbsp;
            <span
              className="text_primary cursor-pointer"
              onClick={handleForgetPassword}
              style={{ cursor: "pointer" }}
            >
              password?
            </span>
          </span>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
