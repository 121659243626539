import axios from "axios";
import { Environment } from "../../Shared/constants/AppConstants";
import swal from "sweetalert";

const instance = axios.create({
  baseURL: Environment.base_url,
});

const nodeInstance = axios.create({
  baseURL: Environment.base_url,
});

instance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const ApiService = {
  async get(url: string) {
    try {
      const res = await instance.get(url);
      return res.data;
    } catch (reason) {
      return await swal(`${reason}`, {
        icon: "warning",
        timer: 2000,
        buttons: [false, false],
      });
    }
  },

  async post(url: string, data: object) {
    try {
      const res = await instance.post(url, data);
      return res.data;
    } catch (reason) {
      return await swal(`${reason}`, {
        icon: "warning",
        timer: 2000,
        buttons: [false, false],
      });
    }
  },

  async put(url: string, data: object) {
    try {
      const res = await instance.put(url, data);
      return res.data;
    } catch (reason) {
      return await swal(`${reason}`, {
        icon: "warning",
        timer: 2000,
        buttons: [false, false],
      });
    }
  },

  async delete(url: string) {
    try {
      const res = await instance.delete(url);
      return res.data;
    } catch (reason) {
      return await swal(`${reason}`, {
        icon: "warning",
        timer: 2000,
        buttons: [false, false],
      });
    }
  },

  async deletewithpayload(url: string, data: object) {
    try {
      const res = await instance.delete(url, { data });
      return res.data;
    } catch (reason) {
      return await swal(`${reason}`, {
        icon: "warning",
        timer: 2000,
        buttons: [false, false],
      });
    }
  },

  async patch(url: string, data: object) {
    try {
      const res = await instance.patch(url, data);
      return res.data;
    } catch (reason) {
      return await swal(`${reason}`, {
        icon: "warning",
        timer: 2000,
        buttons: [false, false],
      });
    }
  },
};
const NodeApiService = {
  async get(url: string) {
    const res = await nodeInstance.get(url);
    return res.data;
  },

  async post(url: string, data: object) {
    try {
      const res = await nodeInstance.post(url, data);
      return res.data;
    } catch (reason) {
      return await swal(`${reason}`, {
        icon: "warning",
        timer: 2000,
        buttons: [false, false],
      });
    }
  },

  async put(url: string, data: object) {
    try {
      const res = await nodeInstance.put(url, data);
      return res.data;
    } catch (reason) {
      return await swal(`${reason}`, {
        icon: "warning",
        timer: 2000,
        buttons: [false, false],
      });
    }
  },

  async delete(url: string, data: object) {
    try {
      const res = await nodeInstance.delete(url, { data });
      return res.data;
    } catch (reason) {
      return await swal(`${reason}`, {
        icon: "warning",
        timer: 2000,
        buttons: [false, false],
      });
    }
  },
};

export { ApiService, NodeApiService };
