import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import "./SignUP.css";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/AppHooks";
import { setSignUpEmail } from "../reducer/reducer";
import { getOccupation, postSignUp } from "../service/SignInServices";
import { toast } from "react-toastify";
import AnimatedLoader from "../../Shared/components/ui_componenets/animatedloader/AnimatedLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [validated, setValidated] = useState(false);
  const [occData, setOccData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    confirm_password: "",
    occupation: "",
    location: "",
  });
  const [passwordsMatch, setPasswordsMatch] = useState(true); // State to track if passwords match

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    // if (name === "password") {
    //   if (!passwordRegex.test(value)) {
    //     console.log(
    //       "Password must contain at least one uppercase letter, one lowercase letter, one special character, and one number, and be at least 8 characters long"
    //     );
    //   }
    // }

    if (name === "confirm_password") {
      // Check if passwords match
      if (value !== formData.password) {
        setPasswordsMatch(false);
      } else {
        setPasswordsMatch(true);
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (!form.checkValidity() || !passwordsMatch) {
      event.stopPropagation();
    } else {
      setLoading(true);

      postSignUp(formData).then((res) => {
        if (res.data.statusCode === 201 || res.data.statusCode === 200) {
          dispatch(setSignUpEmail(formData.email));
          toast.success(res.data.statusMessage);
          setLoading(false);
          navigate("/verify");
        } else {
          setLoading(false);
          toast.error(res.data.statusMessage);
        }
      });
    }

    setValidated(true);
  };

  const handleChange = () => {
    navigate("/login");
  };

  useEffect(() => {
    getoccputation();
  }, []);

  const getoccputation = () => {
    getOccupation().then((res) => {
      console.log("Res", res);
      if (res.data.statusCode === 200) {
        setOccData(res.data.data);
      } else {
        toast.error(res.data.statusMessage);
      }
    });
  };

  // Define password requirements
  const passwordRequirements = [
    "At least 8 characters long",
    "At least one uppercase letter",
    "At least one lowercase letter",
    "At least one special character",
    "At least one number",
  ];

  // Function to check if the password meets all requirements
  const isPasswordValid = (password: any) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center page-signup">
      <form
        className={` col-md-4 col-lg-4 col-sm-12 g-3 signupform  ${
          validated ? "was-validated" : ""
        }`}
        noValidate
        onSubmit={handleSubmit}
      >
        <h1 className=" d-flex justify-content-center">
          <span className="text_primary"> Sign</span>Up
        </h1>
        <div className="">
          <label htmlFor="validationCustom01" className="form-label mb-0">
            First name*
          </label>
          <input
            type="text"
            className="form-control"
            id="validationCustom01"
            name="first_name"
            value={formData.first_name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="">
          <label htmlFor="validationCustom02" className="form-label mb-0">
            Last name*
          </label>
          <input
            type="text"
            className="form-control"
            id="validationCustom02"
            name="last_name"
            value={formData.last_name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="">
          <label htmlFor="validationCustom03" className="form-label mb-0">
            User name*
          </label>
          <input
            type="text"
            className="form-control"
            id="validationCustom03"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="">
          <label htmlFor="validationCustom04" className="form-label mb-0">
            Email*
          </label>
          <input
            type="email"
            className="form-control"
            id="validationCustom04"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="">
          <label htmlFor="validationCustom06" className="form-label mb-0">
            Occupation
          </label>
          <select
            className="form-select"
            aria-label="Default select occupation"
            name="occupation"
            onChange={handleInputChange}
          >
            <option value="" selected>
              Select Occupation
            </option>
            {occData.map((item: any, i: number) => (
              <option key={i} value={item.id}>
                {item.title}
              </option>
            ))}
          </select>
        </div>

        <div className="">
          <label htmlFor="validationCustom07" className="form-label mb-0">
            Location
          </label>
          <input
            type="text"
            className="form-control"
            id="validationCustom07"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            // required
          />
        </div>
        <div className="">
          <label htmlFor="validationCustom05" className="form-label mb-0">
            Password*
          </label>
          <div className="input-group">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="validationCustom05"
              style={{ borderRight: 0 }}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
            <button
              className="btn text_primary"
              type="button"
              style={{
                backgroundColor: "#E8F0FE",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderLeft: 0,
                borderTop: "1px solid #ced4da",
                borderBottom: "1px solid #ced4da",
                borderRight: "1px solid #ced4da",
                outline: "none",
                boxShadow: "none",
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </button>
          </div>
          {/* Display password requirements */}
          <div className="password-requirements">
            <ul>
              {passwordRequirements.map((requirement, index) => (
                <li
                  key={index}
                  className={
                    isPasswordValid(formData.password)
                      ? "d-none"
                      : "text-danger"
                  }
                  style={{
                    fontSize: "10px",
                  }}
                >
                  {requirement}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="">
          <label htmlFor="validationCustom06" className="form-label mb-0">
            Confirm Password*
          </label>
          <div className="input-group">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="validationCustom06"
              style={{ borderRight: 0 }}
              name="confirm_password"
              value={formData.confirm_password}
              onChange={handleInputChange}
              required
            />
            <button
              className="btn text_primary"
              type="button"
              style={{
                backgroundColor: "#E8F0FE",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderLeft: 0,
                borderTop: "1px solid #ced4da",
                borderBottom: "1px solid #ced4da",
                borderRight: "1px solid #ced4da",
                outline: "none",
                boxShadow: "none",
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </button>
          </div>
          {/* Display password match message */}
          {!passwordsMatch && (
            <div className="text-danger">Passwords do not match</div>
          )}
          <span className=" d-flex justify-content-start text_xs">
            already have an account &nbsp;
            <span
              className="text_primary cursor-pointer"
              onClick={handleChange}
              style={{ cursor: "pointer" }}
            >
              LogIn*
            </span>
          </span>
        </div>
        {/* Other form fields... */}
        <div className="">
          <button
            className="btn btn_primary"
            type="submit"
            disabled={!passwordsMatch}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignUp;

// import React, { useEffect, useState } from "react";
// import { Navigate } from "react-router-dom";
// import "./SignUP.css";
// import { useNavigate } from "react-router-dom";
// import { useAppDispatch } from "../../hooks/AppHooks";
// import { setSignUpEmail } from "../reducer/reducer";
// import { getOccupation, postSignUp } from "../service/SignInServices";
// import { toast } from "react-toastify";
// import AnimatedLoader from "../../Shared/components/ui_componenets/animatedloader/AnimatedLoader";

// const SignUp = () => {
//   const navigate = useNavigate();
//   const dispatch = useAppDispatch();
//   const [validated, setValidated] = useState(false);
//   const [occData, setOccData] = useState<any>([]);
//   const [loading, setLoading] = useState(false);
//   const [formData, setFormData] = useState({
//     first_name: "",
//     last_name: "",
//     username: "",
//     email: "",
//     password: "",
//     confirm_password: "",
//     occupation: "",
//     location: "",
//   });

//   const handleInputChange = (event: any) => {
//     const target = event.target;
//     const value = target.type === "checkbox" ? target.checked : target.value;
//     const name = target.name;

//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = (event: any) => {
//     event.preventDefault();

//     const form = event.currentTarget;
//     if (!form.checkValidity()) {
//       event.stopPropagation();
//     } else {
//       setLoading(true);

//       if (formData.password !== formData.confirm_password) {
//         toast.error("Password and Confirm Password should be same ");
//         setLoading(false);
//       } else {
//         postSignUp(formData).then((res) => {
//           if (res.data.statusCode === 201 || res.data.statusCode === 200) {
//             dispatch(setSignUpEmail(formData.email));
//             toast.success(res.data.statusMessage);
//             setLoading(false);
//             navigate("/verify");
//           } else {
//             setLoading(false);
//             toast.error(res.data.statusMessage);
//           }
//         });
//       }
//     }

//     setValidated(true);
//   };
//   const handleChange = () => {
//     navigate("/login");
//   };
//   useEffect(() => {
//     getoccputation();
//   }, []);

//   const getoccputation = () => {
//     getOccupation().then((res) => {
//       console.log("Res", res);
//       if (res.data.statusCode === 200) {
//         setOccData(res.data.data);
//       } else {
//         toast.error(res.data.statusMessage);
//       }
//     });
//   };

//   return (
//     <div className="d-flex flex-column justify-content-center align-items-center page-signup">
//       <form
//         className={` col-md-4 col-lg-4 col-sm-12 g-3 signupform  ${
//           validated ? "was-validated" : ""
//         }`}
//         noValidate
//         onSubmit={handleSubmit}
//       >
//         <h1 className=" d-flex justify-content-center">
//           <span className="text_primary"> Sign</span>Up
//         </h1>
//         <div className="">
//           <label htmlFor="validationCustom01" className="form-label mb-0">
//             First name*
//           </label>
//           <input
//             type="text"
//             className="form-control"
//             id="validationCustom01"
//             name="first_name"
//             value={formData.first_name}
//             onChange={handleInputChange}
//             required
//           />
//         </div>
//         <div className="">
//           <label htmlFor="validationCustom02" className="form-label mb-0">
//             Last name*
//           </label>
//           <input
//             type="text"
//             className="form-control"
//             id="validationCustom02"
//             name="last_name"
//             value={formData.last_name}
//             onChange={handleInputChange}
//             required
//           />
//         </div>
//         <div className="">
//           <label htmlFor="validationCustom03" className="form-label mb-0">
//             User name*
//           </label>
//           <input
//             type="text"
//             className="form-control"
//             id="validationCustom03"
//             name="username"
//             value={formData.username}
//             onChange={handleInputChange}
//             required
//           />
//         </div>
//         <div className="">
//           <label htmlFor="validationCustom04" className="form-label mb-0">
//             Email*
//           </label>
//           <input
//             type="email"
//             className="form-control"
//             id="validationCustom04"
//             name="email"
//             value={formData.email}
//             onChange={handleInputChange}
//             required
//           />
//         </div>
//         <div className="">
//           <label htmlFor="validationCustom06" className="form-label mb-0">
//             Occupation
//           </label>
//           <select
//             className="form-select"
//             aria-label="Default select occupation"
//             name="occupation"
//             onChange={handleInputChange}
//           >
//             <option value="" selected>
//               Select Occupation
//             </option>
//             <option value="1">Student</option>
//             <option value="2">Professional</option>
//             <option value="3">None</option>
//           </select>
//         </div>

//         <div className="">
//           <label htmlFor="validationCustom07" className="form-label mb-0">
//             Location
//           </label>
//           <input
//             type="text"
//             className="form-control"
//             id="validationCustom07"
//             name="location"
//             value={formData.location}
//             onChange={handleInputChange}
//             // required
//           />
//         </div>
//         <div className="">
//           <label htmlFor="validationCustom05" className="form-label mb-0">
//             Password*
//           </label>
//           <input
//             type="password"
//             className="form-control"
//             id="validationCustom05"
//             name="password"
//             value={formData.password}
//             onChange={handleInputChange}
//             required
//           />
//         </div>
//         <div className="">
//           <label htmlFor="validationCustom06" className="form-label mb-0">
//             Confirm Password*
//           </label>
//           <input
//             type="password"
//             className="form-control"
//             id="validationCustom06"
//             name="confirm_password"
//             value={formData.confirm_password}
//             onChange={handleInputChange}
//             required
//           />
//           <span className=" d-flex justify-content-start text_xs">
//             already have an account &nbsp;
//             <span
//               className="text_primary cursor-pointer"
//               onClick={handleChange}
//               style={{ cursor: "pointer" }}
//             >
//               LogIn*
//             </span>
//           </span>
//         </div>
//         <div className="">
//           <button className="btn btn_primary" type="submit">
//             Submit
//           </button>
//         </div>
//       </form>
//       {/* {<AnimatedLoader />} */}
//     </div>
//   );
// };

// export default SignUp;
