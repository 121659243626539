import { ReactNode } from "react";
import Header from "../Shared/components/navbar/Header";
import Footer from "../Shared/components/Footer/Footer";

type LayoutProps = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="d-flex flex-column justify-content-between  min-vh-100">
      <Header />
      <div className="mt-5 mb-5">{children}</div>

      <Footer />
    </div>
  );
};

export default Layout;
