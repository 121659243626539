import "./App.css";
import MainRoutes from "./Routes/MainRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <MainRoutes />

      <ToastContainer
        autoClose={10000}
        pauseOnHover
        style={{ top: "5rem", right: "1rem" }}
      />
    </>
  );
}

export default App;
