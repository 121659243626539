import React, { useState } from "react";
import { toast } from "react-toastify";
import { uploadfile } from "../service/service";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/AppHooks";
import { setTabs } from "../../../login/reducer/reducer";

const NewTicket = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [fileName, setFileName] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState<any>(null);

  const handleFileNameChange = (e: any) => {
    setFileName(e.target.value);
  };

  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value);
  };

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (file) {
      const part = file.name.split(".");
      if (part[part.length - 1] === "csv") {
        const formData = new FormData();
        formData.append("name", fileName);
        formData.append("description", description);
        formData.append("file", file);
        uploadfile(formData)
          .then((res) => {
            if (res.statusCode === 200) {
              navigate("/ticket");
            } else if (res.statusCode === 401) {
              sessionStorage.setItem("accessToken", "");
              sessionStorage.setItem("is_superuser", "");
              dispatch(setTabs([]));
              navigate("/");
            } else {
              toast.error(res.statusMessage);
            }
          })
          .catch(() => {
            sessionStorage.setItem("accessToken", "");
            sessionStorage.setItem("is_superuser", "");
            dispatch(setTabs([]));
            navigate("/");
          });
      } else {
        toast.warning("Upload CSV File ");
      }
    }
  };

  return (
    <>
      <div className="page-section position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="">
              <h1 className="text-center mb-3">
                Create A New RNA Decode Analysis Request
              </h1>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="fileName" className="form-label">
                    File Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="fileName"
                    placeholder="File Name"
                    value={fileName}
                    onChange={handleFileNameChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <textarea
                    className="form-control"
                    value={description}
                    onChange={handleDescriptionChange}
                    id="description"
                    rows={3}
                  ></textarea>
                </div>
                <div className="mb-3">
                  <label htmlFor="formFile" className="form-label">
                    Upload File
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="d-flex justify-content-end mb-2">
                  <button className="btn btn_primary">Upload File</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <AnimatedLoader />
      <div className="overlay"></div> */}
      </div>
    </>
  );
};

export default NewTicket;
