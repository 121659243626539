import React, { useEffect, useState } from "react";
import {
  EditUser,
  allowStatus,
  deleteUser,
  getUserData,
} from "../service/HomeServices";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import "../Home.css";
import UpdateUser from "../update/UpdateUser";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/AppHooks";
import { setTabs } from "../../../login/reducer/reducer";
import swal from "sweetalert";
import Pagination from "../../../Shared/components/pagination/Pagination";

const User = () => {
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  useEffect(() => {
    getUserList();
  }, []);
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    getUserList();
  }, [currentPage]);
  const getUserList = () => {
    getUserData(currentPage)
      .then((res) => {
        if (res.statusCode === 200) {
          // toast.success(res.statusMessage);
          setUserList(res.data);
          setTotalPages(res.total_pages);
        } else if (res.statusCode === 401) {
          sessionStorage.setItem("accessToken", "");
          sessionStorage.setItem("is_superuser", "");
          dispatch(setTabs([]));
          navigate("/");
        } else {
          toast.error(res.statusMessage);
        }
      })
      .catch(() => {
        sessionStorage.setItem("accessToken", "");
        sessionStorage.setItem("is_superuser", "");
        dispatch(setTabs([]));
        navigate("/");
      });
  };
  const handleCheckboxChange = (event: any) => {
    allowStatus({
      user_id: event.target.value,
      status: event.target.checked,
    })
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.statusMessage);
          getUserList();
        } else if (res.statusCode === 401) {
          sessionStorage.setItem("accessToken", "");
          sessionStorage.setItem("is_superuser", "");
          dispatch(setTabs([]));
          navigate("/");
        } else {
          toast.error(res.statusMessage);
        }
      })
      .catch(() => {
        sessionStorage.setItem("accessToken", "");
        sessionStorage.setItem("is_superuser", "");
        dispatch(setTabs([]));
        navigate("/");
      });
  };
  const handleSuperUser = (e: any) => {
    const user: any = userList.filter(
      (item: any) => item.id == e.target.value
    )[0];
    if (user) {
      user.is_superuser = e.target.checked;
      EditUser(user)
        .then((res) => {
          if (res.statusCode === 200) {
            toast.success(res.statusMessage);
            getUserList();
          } else if (res.statusCode === 401) {
            sessionStorage.setItem("accessToken", "");
            sessionStorage.setItem("is_superuser", "");
            dispatch(setTabs([]));
            navigate("/");
          } else {
            toast.error(res.statusMessage);
          }
        })
        .catch(() => {
          sessionStorage.setItem("accessToken", "");
          sessionStorage.setItem("is_superuser", "");
          dispatch(setTabs([]));
          navigate("/");
        });
    }
  };

  const handleDelete = (id: number) => {
    swal({
      title: "Are you sure?",
      text: "This will delete all your data from our database?",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
          className: "bg-success text-white",
          closeModal: true, // Specify whether clicking cancel should close the dialog
        },
        confirm: {
          text: "Delete",
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: false,
        },
      },
    }).then(async (willDelete) => {
      if (willDelete) {
        swal("Changed!", "Your item has been Change.", "success");

        deleteUser(id)
          .then((res) => {
            if (res.statusCode === 200) {
              toast.success(res.statusMessage);
              getUserList();
            } else if (res.statusCode === 401) {
              sessionStorage.setItem("accessToken", "");
              sessionStorage.setItem("is_superuser", "");
              dispatch(setTabs([]));
              navigate("/");
            } else {
              toast.error(res.statusMessage);
            }
          })
          .catch(() => {
            sessionStorage.setItem("accessToken", "");
            sessionStorage.setItem("is_superuser", "");
            dispatch(setTabs([]));
            navigate("/");
          });
      } else {
        swal("Cancelled", "Your item is safe :)", "error");
      }
    });
  };
  return (
    <div className="container py-2 page-home">
      <table className="table table-striped border">
        <thead>
          <tr>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Email</th>
            <th scope="col">Joining Date</th>
            <th scope="col">Last LogIn</th>
            <th scope="col">Permession</th>
            <th scope="col">Super User</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          {userList.map((item: any, i) => (
            <tr key={i}>
              <td>{item.first_name}</td>
              <td>{item.last_name}</td>
              <td>{item.email}</td>
              <td>{item.date_joined}</td>
              <td>{item.last_login}</td>
              <td>
                <div className=" form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    value={item.id}
                    onChange={handleCheckboxChange}
                    checked={item.is_allowed ? true : false}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </td>
              <td>
                <div className=" form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="superuser"
                    value={item.id}
                    name="is_superuser"
                    onChange={handleSuperUser}
                    checked={item.is_superuser ? true : false}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </td>
              <td>
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="text-danger py-1 px-2 rounded"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDelete(item.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default User;
