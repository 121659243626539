import React, { useState } from "react";
import { sendEmail } from "../service/SignInServices";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ForgetEmail = () => {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity()) {
      sendEmail(formData.email).then((res) => {
        if (res.statusCode === 200) {
          navigate("/login");
        } else {
          toast.error(res.statusMessage);
        }
      });
    }

    setValidated(true);
  };
  return (
    <div className="d-flex flex-column justify-content-center align-items-center page-login">
      <form
        className={`col-md-4 col-lg-4 col-sm-12 loginform ${
          validated ? "was-validated" : ""
        }`}
        noValidate
        onSubmit={handleSubmit}
      >
        <h2 className="mb-3 d-flex justify-content-center ">
          <span className="text_primary">Change &nbsp;</span>Password
        </h2>
        <div className="mb-3 w-100">
          <label htmlFor="textinfo" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="textinfo"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="name@example.com"
            required
          />
        </div>
        <div className="d-flex justify-content-end w-100">
          <button className="btn btn_primary" type="submit">
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default ForgetEmail;
