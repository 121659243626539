import React from "react";

interface Props {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination = ({ currentPage, totalPages, onPageChange }: Props) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const handlePageChange = (page: number) => {
    if (page < 1 || page > totalPages) return;
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages < 7) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={`mx-1 py-1 px-3 rounded-lg ${
              i === currentPage ? "btn_primary text-white" : "bg-light"
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      if (currentPage - 3 <= 0) {
        for (
          let i = 1;
          i <= currentPage + (7 - currentPage) && i <= totalPages;
          i++
        ) {
          pageNumbers.push(
            <button
              key={i}
              className={`mx-1 py-1 px-3 rounded-lg ${
                i === currentPage ? "bg-primary text-white" : "bg-light"
              }`}
              onClick={() => handlePageChange(i)}
            >
              {i}
            </button>
          );
        }
      } else {
        for (
          let i = currentPage - 3;
          i <= currentPage + 3 && i <= totalPages;
          i++
        ) {
          pageNumbers.push(
            <button
              key={i}
              className={`mx-1 py-1 px-3 rounded-lg ${
                i === currentPage ? "bg-primary text-white" : "bg-light"
              }`}
              onClick={() => handlePageChange(i)}
            >
              {i}
            </button>
          );
        }
      }
    }
    return pageNumbers;
  };

  return (
    <div className="d-flex justify-content-end mt-4 mb-4">
      <span className="text-secondary text-sm d-flex justify-content-center align-items-center">
        Page {currentPage} of {totalPages}
      </span>
      <nav className="d-inline-flex rounded-md">
        <button
          disabled={isFirstPage}
          className={`mx-1 py-1 px-3 rounded-lg ${
            isFirstPage
              ? "bg-body-tertiary text-black"
              : " bg-secondary text-white"
          }`}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          &lt;
        </button>
        {renderPageNumbers()}
        <button
          disabled={isLastPage}
          className={`mx-1 py-1 px-3 rounded-lg ${
            isLastPage
              ? "bg-body-tertiary text-black"
              : "bg-secondary text-white"
          }`}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          &gt;
        </button>
      </nav>
    </div>
  );
};

export default Pagination;
