import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "./OverView.css";
import { Environment } from "../../../Shared/constants/AppConstants";

interface Idata {
  id: number;
  name: string;
  description: string;
  file: string;
  uuid: string;
  status: string;
  created_at: string;
  updated_at: string;
  user_email: string;
  result: string;
 csv_data?: any[];
}

const OverView = (props: { data: Idata }) => {
  const [data, setData] = useState<Idata>();
  const [modalId, setModalId] = useState<string>("");

  useEffect(() => {
    setData(props.data);
    console.log("props.data", props.data);
    setModalId(`overview_${props.data.id}`); // Generate unique modal id
  }, [props.data]);

  return (
    <div className="overview">
      <FontAwesomeIcon
        icon={faCircleInfo}
        className="text_primary "
        style={{ cursor: "pointer" }}
        data-bs-toggle="modal"
        data-bs-target={`#${modalId}`} // Use unique modal id here
      />

      <div
        className="modal fade"
        id={modalId} // Use unique modal id here
        // tabindex="-1"
        aria-labelledby={`${modalId}_model`} // Use unique modal id here
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title text_primary fs-5"
                id={`${modalId}_model`}
              >
                Over View Ticket
              </h1>{" "}
              {/* Use unique modal id here */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex flex-column">
                {" "}
                <div className="d-flex w-100">
                  <strong className="form-label mb-0 w-25">ID</strong>
                  <label
                    htmlFor={`${modalId}_id`}
                    className="form-label mb-0 ml-2 w-75"
                  >
                    {data?.id}
                  </label>
                </div>
                <div className="d-flex w-100">
                  <strong className="form-label mb-0 w-25">Name</strong>
                  <label
                    htmlFor={`${modalId}_name`}
                    className="form-label mb-0 ml-2 w-75"
                  >
                    {data?.name}
                  </label>
                </div>
                <div className="d-flex w-100">
                  <strong className="form-label mb-0 w-25">Email</strong>
                  <label
                    htmlFor={`${modalId}_email`}
                    className="form-label mb-0 ml-2 w-75 "
                  >
                    {data?.user_email}
                  </label>
                </div>
                <div className="d-flex w-100">
                  <strong className="form-label mb-0 w-25">Description</strong>
                  <label
                    htmlFor={`${modalId}_description`}
                    className="form-label mb-0 ml-2 w-75 "
                  >
                    {data?.description}
                  </label>
                </div>
                <div className="d-flex w-100">
                  <strong className="form-label mb-0 w-25">Status</strong>
                  <label
                    htmlFor={`${modalId}_status`}
                    className="form-label mb-0 ml-2 w-75"
                  >
                    {data?.status}
                  </label>
                </div>
                <div className="d-flex w-100">
                  <strong className="form-label mb-0 w-25">Created At</strong>
                  <label
                    htmlFor={`${modalId}_created_at`}
                    className="form-label mb-0 ml-2 w-75"
                  >
                    {data?.created_at}
                  </label>
                </div>
                <div className="d-flex w-100">
                  <strong className="form-label mb-0 w-25">Updated At</strong>
                  <label
                    htmlFor={`${modalId}_updated_at`}
                    className="form-label mb-0 ml-2 w-75"
                  >
                    {data?.updated_at}
                  </label>
                </div>
              </div>

              <div className="w-100 d-flex">
                <strong className="form-label mb-0 w-25">Results</strong>

                {data?.status === "Completed" ? (

                   <div className="d-flex justify-content-between">
                    {data?.csv_data?.map((data, index) => (
                      <div key={index} className="d-flex flex-column">
                        <div>&nbsp;:{data[""]}</div>
                        <div>x:{data["x"]}</div>
                      </div>
                    ))}
                    <img
                      src={data?.result ? Environment.base_url+data?.result : ""}
                      alt="results"
                      style={{
                        width: "20rem",
                        height: "15rem",
                      }}
                    />
                  </div>
                ) : (
                  <label
                    htmlFor={`${modalId}_updated_at`}
                    className="form-label mb-0 "
                    style={{
                      marginLeft: "4rem",
                    }}
                  >
                    {data?.status}
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverView;
