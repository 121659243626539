import React, { useEffect, useState } from "react";
import "./Header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/AppHooks";
import { useAppDispatch } from "../../../hooks/AppHooks";
import { Iheadertabs } from "../../model/Header";
import { supperUser, user } from "../../../login/contants/Constants";
import { setTabs } from "../../../login/reducer/reducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import ChangePassword from "../../../login/changepassword/ChangePassword";
import { faUser, faCaretDown } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const [data, setData] = useState<any[] | undefined>();
  const [isLogin, setIsLogIn] = useState(false);
  const tabs = useAppSelector((state) => state.authTabs.tabs);
  const token = sessionStorage.getItem("accessToken");
  const is_supperUser = sessionStorage.getItem("is_superuser");
  const handlelogin = () => {
    navigate("/login");
  };
  useEffect(() => {
    if (token) {
      if (is_supperUser === "true") {
        dispatch(setTabs(supperUser));
      } else {
        dispatch(setTabs(user));
      }
    }
  }, [token, is_supperUser]);

  return (
    <>
      <header className="user-select-none ">
        <nav className="navbar navbar-expand-lg bg-body-tertiary header-page">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <span className="text_primary">RNAdecode.AI</span>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link
                    className={`nav-link ${location === "/" ? "active" : ""}`}
                    // onClick={() => navigate("/")}
                    to="/"
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      location === "/manual" ? "active" : ""
                    }`}
                    aria-current="page"
                    // onClick={() => navigate("/manual")}
                    to="/manual"
                  >
                    Manual
                  </Link>
                </li>
                {tabs?.map((item: any, i: number) => (
                  <li className="nav-item" key={item.id}>
                    <Link
                      className={`nav-link ${
                        location === item?.title ? "active" : ""
                      }`}
                      aria-current="page"
                      // onClick={() => navigate(item?.title)}
                      to={item.title}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
                {tabs.length > 0 ? (
                  ""
                ) : (
                  // (
                  //   <li className="nav-item">
                  //     <Link
                  //       className="btn btn_dangerous ml-lg-3"
                  //       onClick={handlelogout}
                  //     >
                  //       Logout
                  //     </Link>
                  //   </li>
                  // )
                  <li className="nav-item">
                    <button
                      className="btn btn_primary ml-lg-3"
                      onClick={handlelogin}
                    >
                      Login / Register
                    </button>
                  </li>
                )}
              </ul>
            </div>
            {tabs.length > 0 ? (
              <div className="ml-3">
                <ChangePassword />
              </div>
            ) : (
              ""
            )}{" "}
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
