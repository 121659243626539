import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Manual from "../pages/manual/Manual";
import ProtectedLayout from "../Shared/components/protectedlayout/ProtectedLayout";
import SignIn from "../login/signin/SignIn";
import SignUp from "../login/signup/SignUp";
import EmailVerify from "../login/emailverify/EmailVerify";
import Home from "../pages/home/Home";
import Header from "../Shared/components/navbar/Header";
import Footer from "../Shared/components/Footer/Footer";
import Loader from "../Shared/components/ui_componenets/loader/Loader";
import Marburg from "../pages/marburg/Marburg";
import Ticket from "../pages/ticket/Ticket";
import NewTicket from "../pages/ticket/newTicket/NewTicket";
import ForgetEmail from "../login/forgetemail/ForgetEmail";
import Profile from "../pages/profile/Profile";
import Impressum from "../pages/Impressum/Impressum";
import TermsandConditions from "../pages/terms/TermsandConditions";
const About = lazy(() => import("../pages/aboutus/About"));

const MainRoutes = () => {
  return (
    <>
      {/* <Header /> */}
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<ProtectedLayout />}>
            <Route path="/" element={<About />} />
            <Route path="/manual" element={<Manual />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/verify" element={<EmailVerify />} />
            <Route path="/forgetemail" element={<ForgetEmail />} />
            <Route path="/admin" element={<Home />} />
            <Route path="/marburg" element={<Marburg />} />
            <Route path="/ticket" element={<Ticket />} />
            <Route path="/newTicket" element={<NewTicket />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/Impressum" element={<Impressum />} />
            <Route
              path="/termsandconditions"
              element={<TermsandConditions />}
            />
          </Route>
        </Routes>
      </Suspense>
      {/* <Footer /> */}
    </>
  );
};

export default MainRoutes;
