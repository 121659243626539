import React from "react";

const Marburg = () => {
  return (
    <div className="page-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="">
            <h1 className="text-center mb-3">TCGA Data</h1>
            <div className="text-lg">
              <p>
                As an example of the RNA data which can be processed with the RNA-Decode AI,
                we provide you with a dataset which was downloaded from
                a <a target="_blank" rel="noopener noreferrer" href="https://portal.gdc.cancer.gov/">publicly available data source</a> from
                the <a target="_blank" rel="noopener noreferrer" href="https://www.cancer.gov/ccg/research/genome-sequencing/tcga">TCGA project</a>.
                The data was processed according to a convention called FPKM and then using a log transform.
                The final result is used a training dataset for the AI which you can <a href="media/TCGA.zip" download>download here</a>.
              </p>
              <p>We are glad to receive your feedback via e-mail.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marburg;