import { faCaretDown, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import "./ChangePassword.css";
import { getUser, setPassword } from "../service/SignInServices";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../hooks/AppHooks";
import { setTabs } from "../reducer/reducer";

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const profileRef = useRef<HTMLDivElement>(null);
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  const [formData, setFormData] = useState({
    old_password: "",
    new_password: "",
  });
  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Specify the type of event
      if (
        profileRef.current &&
        event.target &&
        !profileRef.current.contains(event.target as Node)
      ) {
        // Cast event.target to Node
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getUser()
      .then((res) => {
        if (res.statusCode === 200) {
          setUserData(res.data);
        } else if (res.statusCode === 401) {
          sessionStorage.setItem("accessToken", "");
          sessionStorage.setItem("is_superuser", "");
          dispatch(setTabs([]));
          navigate("/");
        } else {
          toast.error(res.statusMessage);
        }
      })
      .catch(() => {
        sessionStorage.setItem("accessToken", "");
        sessionStorage.setItem("is_superuser", "");
        dispatch(setTabs([]));
        navigate("/");
      });
  }, []);

  const handleSubmit = () => {
    setPassword(formData)
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.statusMessage);
        } else if (res.statusCode === 401) {
          sessionStorage.setItem("accessToken", "");
          sessionStorage.setItem("is_superuser", "");
          dispatch(setTabs([]));
          navigate("/");
        } else {
          toast.error(res.statusMessage);
        }
      })
      .catch(() => {
        sessionStorage.setItem("accessToken", "");
        sessionStorage.setItem("is_superuser", "");
        dispatch(setTabs([]));
        navigate("/");
      });
  };
  const handlelogout = () => {
    setShowMenu(false);
    sessionStorage.setItem("accessToken", "");
    sessionStorage.setItem("is_superuser", "");
    dispatch(setTabs([]));
    navigate("/");
  };
  const handleProfile = () => {
    setShowMenu(!showMenu);
    navigate("/profile");
  };
  return (
    <>
      <div className="position-relative">
        <span
          className="btn btn_primary"
          onClick={() => setShowMenu(!showMenu)}
        >
          <FontAwesomeIcon icon={faUser} size="lg" />
          <FontAwesomeIcon icon={faCaretDown} size="sm" className="ml-1" />
        </span>
        <div ref={profileRef} className={`profile ${showMenu ? "show" : ""}`}>
          <div className="triangle"></div>
          <div className="position-absolute headeroption end-0 ">
            <ul className="">
              <li onClick={() => handleProfile()}>Profile</li>
              <li onClick={handlelogout}>Logout</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <div
        className="modal fade"
        id="changemodel"
        aria-labelledby="changepasswordmodal"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="changepasswordmodal">
                Change Password
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-around">
                <div className="d-flex flex-column">
                  <label
                    htmlFor="validationCustom05"
                    className="form-label mb-0"
                  >
                    Name
                  </label>
                  <label
                    htmlFor="validationCustom05"
                    className="form-label mb-0"
                  >
                    Email
                  </label>
                </div>
                <div className="d-flex flex-column">
                  <label
                    htmlFor="validationCustom05"
                    className="form-label mb-0"
                  >
                    {userData?.username}
                  </label>
                  <label
                    htmlFor="validationCustom05"
                    className="form-label mb-0"
                  >
                    {userData?.email}
                  </label>
                </div>
              </div>

              <div className="mt-4">
                <label htmlFor="validationCustom05" className="form-label mb-0">
                  Old Passward
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationCustom05"
                  name="old_password"
                  // value={formData.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="">
                <label htmlFor="validationCustom06" className="form-label mb-0">
                  New Password
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationCustom06"
                  name="new_password"
                  // value={formData.confirm_password}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn bg-body-tertiary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn_primary"
                onClick={handleSubmit}
                data-bs-dismiss="modal"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ChangePassword;
