import { Navigate, useOutlet, useLocation } from "react-router-dom";
import Layout from "../../../layout/Layout";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const ProtectedLayout = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const outlet = useOutlet();
  const token = sessionStorage.getItem("accessToken");
  // if (!token) {
  //   // return <Navigate to="/login" />;
  //   navigate("/");
  // }
  const ScrollToTopOnMount = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return null;
  };

  const ScrollToTopOnRouteChange = () => {
    useEffect(() => {
      const handleRouteChange = () => {
        window.scrollTo(0, 0);
      };

      // Listen for changes in window location
      window.addEventListener("popstate", handleRouteChange);

      return () => {
        window.removeEventListener("popstate", handleRouteChange);
      };
    }, []);

    return null;
  };

  return (
    <>
      {/* {location === "/login" ? (
        <> {outlet}</>
      ) : ( */}
      <ScrollToTopOnMount />
      <ScrollToTopOnRouteChange />
      <Layout>{outlet}</Layout>

      {/* //   )} */}
    </>
  );
};

export default ProtectedLayout;
