import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/AppHooks";
import {
  getOccupation,
  getUser,
  setPassword,
} from "../../login/service/SignInServices";
import { toast } from "react-toastify";
import { setTabs } from "../../login/reducer/reducer";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "sweetalert";
import { deleteEntity } from "../home/service/HomeServices";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const profileRef = useRef<HTMLDivElement>(null);
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    occupation_name: "",
    location: "",
    password: "",
    confirm_password: "",
    is_superuser: false,
  });
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [formData, setFormData] = useState({
    old_password: "",
    new_password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [occData, setOccData] = useState<any>([]);

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    getUser()
      .then((res) => {
        if (res.statusCode === 200) {
          setUserData(res.data);
        } else if (res.statusCode === 401) {
          sessionStorage.setItem("accessToken", "");
          sessionStorage.setItem("is_superuser", "");
          dispatch(setTabs([]));
          navigate("/");
        } else {
          toast.error(res.statusMessage);
        }
      })
      .catch(() => {
        sessionStorage.setItem("accessToken", "");
        sessionStorage.setItem("is_superuser", "");
        dispatch(setTabs([]));
        navigate("/");
      });
  }, []);

  useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (!isPasswordValid(formData.new_password)) {
      toast.error("Password does not meet the requirements.");
      return;
    }

    setPassword(formData)
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.statusMessage);
        } else if (res.statusCode === 401) {
          sessionStorage.setItem("accessToken", "");
          sessionStorage.setItem("is_superuser", "");
          dispatch(setTabs([]));
          navigate("/");
        } else {
          toast.error(res.statusMessage);
        }
      })
      .catch(() => {
        sessionStorage.setItem("accessToken", "");
        sessionStorage.setItem("is_superuser", "");
        dispatch(setTabs([]));
        navigate("/");
      });
  };

  const passwordRequirements = [
    "At least 8 characters long",
    "At least one uppercase letter",
    "At least one lowercase letter",
    "At least one special character",
    "At least one number",
  ];

  const isPasswordValid = (password: any) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleLogout = () => {
    setShowMenu(false);
    sessionStorage.setItem("accessToken", "");
    sessionStorage.setItem("is_superuser", "");
    dispatch(setTabs([]));
    navigate("/");
  };

  const handleDelete = () => {
    swal({
      title: "Are you sure?",
      text: "This will delete all your data from our database?",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
          className: "bg-success text-white",
          closeModal: true,
        },
        confirm: {
          text: "Delete",
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: false,
        },
      },
    }).then(async (willDelete) => {
      if (willDelete) {
        swal("Changed!", "Your item has been Change.", "success");

        deleteEntity()
          .then((res) => {
            if (res.statusCode === 200) {
              toast.success(res.statusMessage);
              sessionStorage.setItem("accessToken", "");
              sessionStorage.setItem("is_superuser", "");
              dispatch(setTabs([]));
              navigate("/");
            } else if (res.statusCode === 401) {
              sessionStorage.setItem("accessToken", "");
              sessionStorage.setItem("is_superuser", "");
              dispatch(setTabs([]));
              navigate("/");
            } else {
              toast.error(res.statusMessage);
            }
          })
          .catch(() => {
            sessionStorage.setItem("accessToken", "");
            sessionStorage.setItem("is_superuser", "");
            dispatch(setTabs([]));
            navigate("/");
          });
      } else {
        swal("Cancelled", "Your item is safe :)", "error");
      }
    });
  };

  useEffect(() => {
    getOccupation().then((res) => {
      if (res.data.statusCode === 200) {
        setOccData(res.data.data);
      } else {
        toast.error(res.data.statusMessage);
      }
    });
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center page-signup">
      <form
        className={` col-md-4 col-lg-4 col-sm-12 g-3 signupform `}
        noValidate
        onSubmit={handleSubmit}
      >
        <h1 className="d-flex justify-content-center">
          <span className="text_primary">Profile</span>
        </h1>
        <div className="">
          <label htmlFor="validationCustom01" className="form-label mb-0">
            First name*
          </label>
          <input
            type="text"
            className="form-control"
            id="validationCustom01"
            name="first_name"
            value={userData.first_name}
            onChange={handleInputChange}
            disabled
          />
        </div>
        <div className="">
          <label htmlFor="validationCustom02" className="form-label mb-0">
            Last name*
          </label>
          <input
            type="text"
            className="form-control"
            id="validationCustom02"
            name="last_name"
            value={userData.last_name}
            onChange={handleInputChange}
            disabled
          />
        </div>
        <div className="">
          <label htmlFor="validationCustom03" className="form-label mb-0">
            User name*
          </label>
          <input
            type="text"
            className="form-control"
            id="validationCustom03"
            name="username"
            value={userData.username}
            onChange={handleInputChange}
            disabled
          />
        </div>
        <div className="">
          <label htmlFor="validationCustom04" className="form-label mb-0">
            Email*
          </label>
          <input
            type="email"
            className="form-control"
            id="validationCustom04"
            name="email"
            value={userData.email}
            onChange={handleInputChange}
            disabled
          />
        </div>
        <div className="">
          <label htmlFor="validationCustom06" className="form-label mb-0">
            Occupation
          </label>
          <select
            className="form-select"
            aria-label="Default select occupation"
            name="occupation"
            disabled
          >
            {occData?.map((item: any, i: number) => (
              <option
                key={i}
                value={item.id}
                selected={
                  userData.occupation_name === item?.title ? true : false
                }
              >
                {item?.title}
              </option>
            ))}
          </select>
        </div>
        <div className="">
          <label htmlFor="validationCustom07" className="form-label mb-0">
            Location
          </label>
          <input
            type="text"
            className="form-control"
            id="validationCustom07"
            name="location"
            value={userData.location}
            onChange={handleInputChange}
            disabled
          />
        </div>
        <div className="">
          <label htmlFor="validationCustom06" className="form-label mb-0">
            Old Password
          </label>
          <div className="input-group">
          <input
             type={showPassword ? "text" : "password"}
             style={{ borderRight: 0 }}
            className="form-control"
            id="validationCustom06"
            name="old_password"
            value={formData.old_password}
            onChange={handleInputChange}
            required
          />
          <button
              className="btn text_primary"
              type="button"
              style={{
                backgroundColor: "#E8F0FE",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderLeft: 0,
                borderTop: "1px solid #ced4da",
                borderBottom: "1px solid #ced4da",
                borderRight: "1px solid #ced4da",
                outline: "none",
                boxShadow: "none",
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </button></div>
        </div>
        <div className="">
          <label htmlFor="validationCustom05" className="form-label mb-0">
            New Password*
          </label>
          <div className="input-group">
            <input
              type={showPassword ? "text" : "password"}
              style={{ borderRight: 0 }}
              className="form-control"
              id="validationCustom05"
              name="new_password"
              value={formData.new_password}
              onChange={handleInputChange}
              required
            />
            <button
              className="btn text_primary"
              type="button"
              style={{
                backgroundColor: "#E8F0FE",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderLeft: 0,
                borderTop: "1px solid #ced4da",
                borderBottom: "1px solid #ced4da",
                borderRight: "1px solid #ced4da",
                outline: "none",
                boxShadow: "none",
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </button>
          </div>
          <div className="password-requirements">
            <ul>
              {passwordRequirements.map((requirement, index) => (
                <li
                  key={index}
                  className={
                    isPasswordValid(formData.new_password)
                      ? "d-none"
                      : "text-danger"
                  }
                  style={{
                    fontSize: "10px",
                  }}
                >
                  {requirement}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="">
          <button
            className="btn btn_primary"
            disabled={!passwordsMatch}
            type="submit"
          >
            Submit
          </button>
          {userData.is_superuser ? (
            ""
          ) : (
            <button
              type="button"
              className="btn btn-danger ms-1"
              onClick={() => handleDelete()}
            >
              Delete
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Profile;
