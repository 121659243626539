import React from "react";

const TermsandConditions = () => {
  return (
    <div className="page-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="">
            <h1 className="text-center mb-3">Terms and Conditions</h1>
            <div className="text-lg">
              <h1 className="text-start mb-3">Welcome to RNA Decode</h1>
              <p>
                Thank you for choosing RNA Decode. Please read these Terms and
                Conditions ("Terms") carefully before using our website and RNA
                Decode.
              </p>
              <h1 className="text-start mb-3">Acceptance of Terms</h1>
              <p>
                By accessing and using RNA Decode, you agree to be bound by
                these Terms and all applicable laws and regulations. If you
                disagree with any part of the terms, you may not access the
                Service. RNA Decode is provided “as is”, without any warranty of
                any kind, express or implied, including but not limited to the
                warranties of merchantability, fitness for a particular purpose
                and noninfringement. In no events shall the collaborations
                partners, their employees or the university itself, its
                employees or its affiliates or their respective officers or
                directors be liable for any claim, damages or other liability,
                whether in an action of contract, tort or otherwise, arising
                from, out of or in connection with RNA Decode or the use of
                thereof.
              </p>
              <h1 className="text-start mb-3">Data Governance</h1>
              <p>
                <strong>1.</strong> Data Collection: We collect data that has
                been anonymized and stripped of all personally identifiable
                information. Our collection methods adhere to ethical standards
                and legal requirements to ensure that data privacy is
                maintained.
              </p>
              <p>
                <strong>2.</strong> Data Use: The anonymized data is used for a
                research project of the Philipps-University Marburg in Germany
                to providing insights and analytics. The data is also used for
                research and development to enhance the functionality of our AI
                algorithms.
              </p>
              <p>
                <strong>3.</strong> Data Sharing: We do not share data with
                third parties unless necessary for the operation of the Service
                or as required by law. When data is shared, it remains in
                anonymized form, and the recipients are bound by confidentiality
                agreements to ensure data protection.
              </p>

              <h1 className="text-start mb-3">Privacy</h1>
              <p>
                <strong>1.</strong> User Information: We may collect information
                about users of the Service for purposes of account management,
                support, and ensuring compliance with these Terms.
              </p>
              <p>
                <strong>2.</strong> Anonymized Data: By using the Service, you
                acknowledge that the anonymized data used cannot be traced back
                to any individual. We take steps to ensure that the data we use
                maintains its anonymized status through various technical
                safeguards.
              </p>
              <h1 className="text-start mb-3">Security</h1>
              <p>
                <strong>1.</strong> Data Security: We implement robust security
                measures to protect the data from unauthorized access,
                alteration, and destruction. These measures include, but are not
                limited to firewalls, and secure server facilities.
              </p>
              <p>
                <strong>2.</strong> User Responsibilities: Users of the Service
                are responsible for maintaining the confidentiality of their
                account credentials. You must notify us immediately of any
                unauthorized use of your account.
              </p>

              <h1 className="text-start mb-3">Changes to Terms</h1>
              <p>
                We reserve the right, at our sole discretion, to modify or
                replace these Terms at any time. What constitutes a material
                change will be determined at our discretion.
              </p>
              <h1 className="text-start mb-3">Governing Law</h1>
              <p>
                These Terms shall be governed and construed in accordance with
                the laws of Germany without regard to its conflict of law
                provisions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsandConditions;