import React, { useEffect, useState } from "react";
import NewTicket from "./newTicket/NewTicket";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { getTickets } from "./service/service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../Shared/components/pagination/Pagination";
import { useAppDispatch } from "../../hooks/AppHooks";
import { setTabs } from "../../login/reducer/reducer";
import OverView from "../home/overview/OverView";
import swal from "sweetalert";
import { deleteticket } from "../home/service/HomeServices";
import { Environment } from "../../Shared/constants/AppConstants";

const Ticket = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    getAllTickets();
  }, []);
  useEffect(() => {
    getAllTickets();
  }, [currentPage]);
  const getAllTickets = () => {
    getTickets(currentPage)
      .then((res) => {
        if (res.statusCode === 200) {
          // setData((prev: any) => [...prev, res.data]);
          setData(res.data);
          setTotalPages(res.total_pages);
        } else if (res.statusCode === 401) {
          sessionStorage.setItem("accessToken", "");
          sessionStorage.setItem("is_superuser", "");
          dispatch(setTabs([]));
          navigate("/");
        } else {
          toast.error(res.statusMessage);
        }
      })
      .catch(() => {
        sessionStorage.setItem("accessToken", "");
        sessionStorage.setItem("is_superuser", "");
        dispatch(setTabs([]));
        navigate("/");
      });
  };

  const extractFileName = (url: string) => {
    if (!url) return;
    const parts = url?.split("/");
    return parts[parts?.length - 1].length > 7
      ? parts[parts?.length - 1].substring(0, 6) + "..."
      : parts[parts?.length - 1];
  };
  const handleFileDownload = (url: string) => {
    window.open(Environment.base_url+url);
  };
  const downloadImage = (imageUrl: string) => {
    const fullUrl = Environment.base_url + imageUrl
    fetch(fullUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'downloaded_image.png'; // Set the desired file name
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch(error => console.error('Error downloading the image:', error));
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const handleDelete = (id: number) => {
    swal({
      title: "Are you sure?",
      text: "This will delete all your data from our database?",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
          className: "bg-success text-white",
          closeModal: true, // Specify whether clicking cancel should close the dialog
        },
        confirm: {
          text: "Delete",
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: false,
        },
      },
    }).then(async (willDelete) => {
      if (willDelete) {
        swal("Changed!", "Your item has been Change.", "success");

        deleteticket(id)
          .then((res) => {
            if (res.statusCode === 200) {
              toast.success(res.statusMessage);
              getAllTickets();
            } else if (res.statusCode === 401) {
              sessionStorage.setItem("accessToken", "");
              sessionStorage.setItem("is_superuser", "");
              dispatch(setTabs([]));
              navigate("/");
            } else {
              toast.error(res.statusMessage);
            }
          })
          .catch(() => {
            sessionStorage.setItem("accessToken", "");
            sessionStorage.setItem("is_superuser", "");
            dispatch(setTabs([]));
            navigate("/");
          });
      } else {
        swal("Cancelled", "Your item is safe :)", "error");
      }
    });
  };

  return (
    <div className="page-section user-select-none">
      <div className="container">
        <div className="row justify-content-center">
          <div className="">
            <h1 className="text-center mb-3">Ticket</h1>
            <div className="text-lg">
              <p>
                Here you see an overview of all your tickets. After creating a
                ticket it will take a few minutes untill the analysis is
                finished. To see the results and ticket details click on View.
              </p>
            </div>
            <div className="d-flex justify-content-end mb-2">
              <button
                className="btn btn_primary"
                onClick={() => navigate("/newTicket")}
              >
                New Ticket
              </button>
              {/* <NewTicket /> */}
            </div>

            <table className="table table-striped ">
              <thead>
                <tr>
                  <th scope="col">Ticket</th>
                  <th scope="col">Status</th>
                  <th scope="col">Created</th>
                  <th scope="col">Last Updated</th>
                  <th scope="col">Description</th>
                  <th scope="col">Results</th>
                  <th scope="col">Over View</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any, i: number) => (
                  <tr key={i}>
                    <td>{item.name}</td>
                    <td>{item.status}</td>
                    <td>{item.created_at}</td>
                    <td>{item.updated_at}</td>
                    <td>
                      {item?.description?.length > 20
                        ? item?.description?.substring(0, 20)
                        : item?.description}
                    </td>
                    <td>
                      <div className="d-flex justify-content-between">
                        <div>{extractFileName(item.result)}</div>
                        <div
                          onClick={() => downloadImage(item.result)}
                          style={{ cursor: "pointer" }}
                        >
                          {item.result ? (
                            <FontAwesomeIcon icon={faDownload} size="sm" />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <OverView data={item} />
                    </td>
                    <td>
                     
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        className="text-danger py-1 px-2 rounded"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDelete(item.id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ticket;
