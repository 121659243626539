import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getPage } from "../../../pages/home/service/HomeServices";
import { useAppDispatch } from "../../../hooks/AppHooks";
import { setTabs } from "../../../login/reducer/reducer";
import { toast } from "react-toastify";
import { pageData } from "../../model/PageModel";

const Footer = () => {
  const [aboutData, setAboutData] = useState<pageData[]>();
  const [referenceData, setReferenceData] = useState<pageData[]>();
  const [contactData, setContactData] = useState<pageData[]>();

  const handleFileDownload = (url: string) => {
    window.open(url);
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    ApiCallData();
  }, []);

  const ApiCallData = async () => {
    try {
      const aboutRes = await ExtractAllData("About US");
      setAboutData(aboutRes);
    } catch (error) {
      console.error(error);
    }
    try {
      const aboutRes = await ExtractAllData("	References");
      setReferenceData(aboutRes);
    } catch (error) {
      console.error(error);
    }
    try {
      const aboutRes = await ExtractAllData("Contact US");
      setContactData(aboutRes);
    } catch (error) {
      console.error(error);
    }
  };

  const ExtractAllData = async (
    name: string
  ): Promise<pageData[] | undefined> => {
    try {
      const res = await getPage(name);
      if (res.data.statusCode === 200) {
        return res.data.data;
      } else if (res.data.statusCode === 401) {
        sessionStorage.setItem("accessToken", "");
        sessionStorage.setItem("is_superuser", "");
        dispatch(setTabs([]));
        navigate("/");
      } else {
        toast.error(res.data.statusMessage);
      }
    } catch (error) {
      sessionStorage.setItem("accessToken", "");
      sessionStorage.setItem("is_superuser", "");
      dispatch(setTabs([]));
      navigate("/");
    }
  };

  return (
    <footer className="page-footer">
      <div className="container">
        <div className="row px-md-3">
          <div className="col-sm-6 col-lg-5 py-3">
            <h5>ABOUT US</h5>
            <ul
              className="footer-menu"
              style={{
                color: "#00D9A5",
              }}
            >
              <li>
                <a href="https://www.iap-gmbh.de/" target="_blank">RNAdecode.AI is an AI Webserver provided by IAP-GmbH.</a>
              </li>
              <li>
                <a href="https://doi.org/10.1007/s13218-022-00782-6" target="_blank">We designed an AI for diagnostic micro RNA data classifying 32 different cancer entities.</a>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-lg-3 py-3"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                marginLeft: "2.5rem",
                width: "100%",
              }}
            >
              <h5>REFERENCES</h5>
            </div>

            <ul
              className="footer-menu"
              style={{
                listStyleType: "disc",
                color: "#00D9A5",
              }}
            >
             <li>
                <Link
                  to="#"
                  onClick={() =>
                    handleFileDownload(
                      "https://doi.org/10.1007/s13218-022-00782-6"
                    )
                  }
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Thrun et al. (2022)
                </Link>
              </li>        
            </ul>
          </div>

          <div className="col-sm-6 col-lg-4 py-3"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                marginLeft: "-2rem",
                width: "100%",
              }}
            >
              <h5>CONTACT US</h5>
            </div>
            <ul
              className="footer-menu"
              style={{
                listStyleType: "disc",
                color: "#00D9A5",
              }}
            >
               <li>
                <Link to="#">Michael Thrun, Privatdozent</Link>
              </li>
              <li>
                <Link to="#">Mathematics and Computer Science</Link>
              </li>
              <li>
                <Link to="#">mthrun [at] mathematik.uni-marburg.de</Link>
              </li>
              <li>
                <Link to="#">Philipps-Universität Marburg</Link>
              </li>
              <li>
                <Link to="#">D-35032 Marburg</Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={() =>
                    handleFileDownload("https://mthrun.github.io/index")
                  }
                >
                  www.deepbionics.de
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <hr />

        <p id="copyright" className="d-flex justify-content-center">
          &copy; 2024
          <Link to="" target="link">
            RNAdecode.AI
          </Link>
          Project
        </p>
        <ul className="footer-menu d-flex justify-content-center">
          <li>
            <Link to="/Impressum">Impressum</Link>
          </li>
          <li className="ms-3">
            <Link to="/termsandconditions">TermsandConditions</Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
